import * as React from 'react';
import * as Lockr from 'lockr';
import isArray from 'lodash/isArray';

// FixMe: workaround for some strange circular dependency issue in the wcf core
import '@egr/xbox/utils/Helper';

import { AppLoaderCircle } from '@egr/xbox/egr-gui-elements/AppLoader/AppLoaderCircle';
import { layoutInformation } from '@egr/xbox/egr-gui-elements/Helper/Darkmode';

let backgroundColor: string | undefined;

try {
    const value: string | undefined = Lockr.get('app-settings');

    /* eslint-disable-next-line no-restricted-properties */
    const data = value != null ? JSON.parse(value) : [];

    if (isArray(data)) {
        const settings = new Map<string, Record<string, string>>(data as Array<[string, Record<string, string>]>);

        switch (settings.get('appSettings')?.layoutMode) {
            case 'light':
                backgroundColor = '#FFFFFF';
                layoutInformation.updateLayoutMode('light');
                break;
            case 'dark':
                backgroundColor = '#3A3A3A';
                layoutInformation.updateLayoutMode('dark');
                break;
            default:
                break;
        }
    }
} catch (e) {
    console.warn(e);
}

export const AppLoader: React.FC = () => {
    const [ component, setComponent ] = React.useState(<AppLoaderCircle background={backgroundColor}/>);

    React.useEffect(
        (): void => {
            void (async (): Promise<void> => {
                const { getApp } = await import('./getApp');

                const RealApp: React.FC = await getApp();

                setComponent(React.createElement(RealApp));
            })();
        },
        [] // call only once
    );
    return component;
};